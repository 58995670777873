.collection-box{
    height: 170px;
    width: 250px;
    color: #fff;
    outline-width: 2px;
    border-radius: 9px !important;
    font-size: 25px;
    cursor: pointer;
    text-align: center;
}

.context-menu-box {
    display: flex;
    flex-direction: column; 
    background-color: white;
    border: 1px solid #ccc;
    border-radius: 5px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
    padding: 3px;
    width: calc(100% + 40px);
  }
  
  .context-menu-box button {
    padding: 5px;
    margin-bottom: 5px;
    border: none;
    background: none;
    cursor: pointer;
  }
  
  .context-menu-box button:last-child {
    margin-bottom: 0;
  }

  .delete-button {
    background-color: red;
    z-index: 100;
    width: 100%;
    color: white; /* Set the text color to white for better contrast */
  }

.full-width-button {
  width: 100%;
  padding: 10px;
  transition: background-color 0.3s ease-in-out; /* smooth transition */
}

.full-width-button:hover {
  background-color: #ccc; /* change to a darker or different color */
}



.collection-transition-enter {
  opacity: 0;
}
.collection-transition-enter-active {
  opacity: 1;
  transition: opacity 300ms ease-in;
}
.collection-transition-exit {
  opacity: 1;
}
.collection-transition-exit-active {
  opacity: 0;
  transition: opacity 300ms ease-out;
}