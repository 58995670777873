/* .input-box {
    width: 200px;
}

.collections-form {
    display:flex;
    justify-content:center;
    align-items: center;
    margin-top: 30%;
}

.create-collection-button {
    align-items: center;
    margin-top: 5%;
    margin-left: 13%;
}

::placeholder {
    text-align: left;
} */

.collections-form {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 0; /* Removes default margin */
    margin-top: 90px;
}

.input-box {
    width: 250px; /* Adjust the width as necessary */
    display: flex;
    flex-direction: column;
    align-items: center;
}

.form-label {
    font-family: "raleway";
    font-size: 27px;
    text-align: center;
    margin-bottom: 10px; /* Adjusts the space between label and input */
}

.create-collection-input {
    width: 100px;
    min-width: 200px;
    max-width: 200px;
    margin-bottom: 10px; /* Space between input and button */
    font-family: "raleway";
    margin-top: 0px;
}

.create-button-container {
    display: flex;
    position: relative;
    justify-content: center;
    align-items: center;
    margin-left: 93px;
    margin-top: -9px;
    
    width: 70px;
}

.create-collection-button {
    background-color: #79b8ff;
    border: none;
    font-family: "raleway";
    width: 100%;
}

::placeholder {
    text-align: left;
}
