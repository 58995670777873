.modal-body{
    height: 200px;
    display:flex;
    justify-content:center;
    align-items: center;
    border-radius: 20px;
}

.modal-content {
    border-radius: 23px;
    -webkit-border-radius: 30px !important;

}

.close-button {
    font-size: 12pt;
    margin-top: 10px;
    margin-right: 10px;
    position:absolute;
    top:0;
    right:0;
}

.creation-form {
    display:flex;
    justify-content:center;
    align-items: center;
}

.This {
    border-radius: 19px;
}

/* Prevent auto-zooming on text input focus on mobile devices */
input[type="text"]:focus,
textarea:focus {
    -webkit-text-size-adjust: 100%;
    text-size-adjust: 100%; /* For other browsers */
}

.create-button-website-artefact {
    margin-top: 15px !important;
}

@media (max-width: 768px) {
    .artefacts-website-form-heading {
        margin-top: 0px !important;
    }
}