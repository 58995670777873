@import url('https://fonts.googleapis.com/css2?family=Raleway:ital,wght@0,200;0,300;0,400;1,200;1,300;1,400&display=swap');

.confirm-delete-modal .modal-content {
    max-height: 230px !important;
    max-width: 370px !important;
    width: 370px !important;
    border-radius: 23px;
    overflow: hidden;
    font-family: 'Raleway', sans-serif;
    border: none; 
}

.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 1000;
  }
  
  .hidden {
    display: none;
  }

.confirm-delete-modal .modal-dialog {
    width: 100%;
    max-width: 370px !important;
    margin: auto; 
    background: transparent;
}

.confirm-delete-modal .modal-header .btn-close {
    border-bottom: none !important;

    position: absolute;
    top: 25px;
    right: 25px;
    z-index: 1;
    background-color: transparent;
    border: none;
    font-size: 1.2rem;
    padding: 0;
}

.confirm-delete-modal .modal-header {
    border-bottom: none !important;

    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 15px 20px 0 20px;

}

.confirm-delete-modal .modal-header .modal-title {
    border-bottom: none !important;
    margin: auto;
    font-size: 1.7rem;

}

.modal-footer {
    border-top: none !important; 
    padding-top: 0 !important;
    display: flex;
    justify-content: center; 
    align-items: center;
    gap: 10px; 
    margin-right: 70px;

}


.modal-body-confirm-delete {
    display: flex;
    justify-content: center;  
    align-items: center;    
    text-align: center;      
    padding: 0 30px !important;          
}


.cancel-button_confirm_delete {
    background-color: red;
    color: white;
    padding: 10px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}

.cancel-button_confirm_delete {
    background-color: grey;
    color: white;
    padding: 10px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}

/* Disable all background interactions while the modal is open */
.disable-background {
  pointer-events: none; /* Disable all pointer events (clicks, etc.) */
  user-select: none;    /* Disable text selection */
  overflow: hidden;     /* Prevent scrolling */
}

.no-select {
    -webkit-touch-callout: none; /* Disable callout, e.g., phone numbers in Safari */
    -webkit-user-select: none;   /* Disable text selection in Safari */
    -khtml-user-select: none;    /* Disable text selection in Konqueror */
    -moz-user-select: none;      /* Disable text selection in Firefox */
    -ms-user-select: none;       /* Disable text selection in Internet Explorer/Edge */
    user-select: none;           /* Disable text selection in non-prefixed browsers */
  }
  