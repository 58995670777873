@media (max-width: 768px) {

  
    .artefact-upload {
      /* Reduce the width by 10% and the height by 5% */
      /* width: 90% !important; */
      height: 180px !important;
    }

    .artefacts-form > div {
        margin-top: 7px !important; /* Increase this value to move the input and button down */
      }
  }
  