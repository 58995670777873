.navbar {
    font-family: raleway;
}
/* 
*/

img {
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -o-user-select: none;
    user-select: none;
  }

.add-icon.btn-light:focus,
.add-icon.btn-light:active,
.add-icon.btn-light:focus-visible {
  outline: none; /* Remove the focus outline */
  box-shadow: none; /* Remove the box-shadow */
  background-color: #E4E4E4; /* Ensure the background color stays consistent */
}

.add-icon.btn.btn-none{
    box-shadow: none !important;
    border: none !important;
    text-decoration: none;
}

/* Remove focus and active state styles for link variant buttons */
.profile-button.btn-link:focus,
.profile-button.btn-link:active,
.profile-button.btn-link:focus-visible {
  outline: none; /* Remove the focus outline */
  box-shadow: none; /* Remove the box-shadow */
  background-color: transparent; /* Ensure background stays transparent */
}


/* .arrow-button-margin{
    text-align: center;
    margin-top: -20px;
}

.arrow-key-boxes {
    background: none;
    height: 24px;
    width: 38px;
    display:flex;
    justify-content:center;
    align-items: center;
}


.arrow-keys {
    float: left;
    margin-top: -36px;
    margin-left: -13px;
    position: relative;
    top: 26px;
    left: 8px;
    color: #9D9D9D;
}

.arrow-key-boxes.btn.btn-light {
    background-color: #EAEAEA;
}
 */


.tabs {
    background: none;
    height: 27px;
    width: 90px;
    display:flex;
    justify-content:center;
    align-items: center;
    padding-top: 20px;
}



.Button:focus {
    outline: none;
}

.tabs-margin{
    margin-top: -20px;
}

.tabs-text {
    margin-top: -6px;
    color: #9D9D9D;
}


.viewing-text {
    display:flex;
    justify-content:center;
}

.breadcrumb-text {
    display:flex;
    justify-content:center;
}



.navbar {
    color: #9D9D9D;

}



/* .nav-whole {
    height: 55px;
    padding: 6px 5px 67px 10px;
    background-color: #f3f3f3; 
    background-color: rgba(243, 243, 243, 0.95);
    border-radius: 10px; 
    
}


.navbar-sub-container {
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 0;
    left: 0;
    right: 0; 
    
} */


.navbar-container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    position: fixed;
    top: 0;
    z-index: 1;
    margin-top: 70px;
    background-color: rgba(243, 243, 243, 0.8);
}

.navbar-sub-container {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(243, 243, 243, 0.95) !important;
    padding: 2px;
    border-radius: 10px;
    width: fit-content; /* Ensure it only takes as much space as its content */
    /* box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1); */

}

.nav-whole {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    /* padding: 6px 5px 67px 10px; */
    /* background-color: rgba(243, 243, 243, 0.95); */
    border-radius: 10px;
    pointer-events: auto;
    user-select: none;
}




.button-margin {
    margin-top: 10px;
}




.line{
    display:flex;
    justify-content:center;
    align-items: center;
    margin: 0 7%;
    border-bottom: 2px solid #D8D8D8;
}



#collasible-nav-dropdown::after {
    display:none;
}



.dropdown-menu.show {
    margin-left: -60px;
}

.logout-button {
    width: 150px;
}

.dropdown-menu.show {
    padding: 1%;
    text-align: center;
}

.data-rr-ui-dropdown-item {
    padding: 0%;
}

.dropdown-divider {
    margin: none;
}



@media (max-width: 768px) {

    .tabs {
        width: 60px !important;
        height: 27px !important;
        transform: translateY(-1px) !important; 

    }
}



@media (max-width: 768px) {
    .reload-text {
      margin-left: -3px !important; /* Move the label to the right */
      text-align: right; /* Align the text to the right */
      display: block; /* Ensure the label stays visible */
    }
  }