.modal-body{
  /* height: 500px !important; */
  display:flex;
  justify-content: center;
  align-items: center;
  border-radius: 20px;
}

.modal-content {
  border-radius: 23px;
  -webkit-border-radius: 30px !important;
}

.choose-type-modal .modal-content {
  -webkit-border-radius: 30px !important;
  max-height: 300px !important;
  height: 300px !important;
  border-radius: 23px;
  overflow: auto; 
  max-width: 400px;
}

.create-artefact-form-modal .modal-body {
  max-height: 500px !important;
  height: 500px !important;
  max-width: 600px !important;
  width: 600px !important;
  padding: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.create-artefact-modal .modal-dialog {
  max-width: 400px !important; 
  width: 400px !important;
  max-height: 970px !important; 
  /* height: auto !important; */
  /* margin: auto; */
}


.input-image {
    width: 15vw;
    max-width: 370px;
}

.artefact-upload {
    /* Other styles remain the same */
    transition: background-color 0.3s ease-in-out; /* Smooth transition for background color */
    /* width: 490px !important; */
    width: 570px !important;

  }

.artefact-upload:hover {
    background-color: rgba(0, 0, 0, 0.05); /* Adds a slight darkening effect on hover */
  }
  



/* Targeting the scrollbar for webkit browsers */
.website-artefacts-form textarea::-webkit-scrollbar {
    width: 8px; /* Makes the scrollbar thinner */
    border-radius: 5px;
  }
  
  .website-artefacts-form textarea::-webkit-scrollbar-thumb {
    background: #ccc; /* Color of the scrollbar thumb */
    border-radius: 5px;
  }
  
  .website-artefacts-form textarea::-webkit-scrollbar-thumb:hover {
    background: #ccc; /* Color of the scrollbar thumb on hover */
  }
  
  /* Removes scrollbar buttons/arrows (for webkit browsers) */
  .website-artefacts-form textarea::-webkit-scrollbar-button {
    display: none;
  }

  .arrow-button {
    background-color: #79b8ff;
    border-radius: 50%;
    border: 2px solid #79b8ff;
    padding: 5px;
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: background-color 0.3s, border-color 0.3s;
    outline: none !important;
    border-style: none !important;
    box-shadow: none !important;
  }

  .add-more-artefact{
    outline: none !important;
    border-style: none !important;
    box-shadow: none !important;
  }
  
  .arrow-button.disabled {
    background-color: #ccc;
    border-color: #ccc;
    /* cursor: not-allowed; */
    cursor: auto !important;
    pointer-events: all !important;

  }
  
  .arrow-button:hover:not(.disabled) {
    background-color: #68a0ff;
    border-color: #68a0ff;
  }
  
  .add-more-artefact {
    background-color: #aaa; /* Default color for the + button */
    transition: background-color 0.3s ease;
  }
  
  .add-more-artefact:hover {
    background-color: #888 !important; /* Darker shade on hover */
  }
  
  .remove-artefact-button {
    background-color: #FF666D !important; /* Default color for the - button */
    transition: background-color 0.3s ease;
    border: none !important; /* Ensure no border appears */
    outline: none !important; /* Ensure no outline appears */
    box-shadow: none !important; /* Ensure no box shadow appears */  
  }
  
  .remove-artefact-button:hover {
    background-color: #cd5357 !important; /* Darker shade on hover */
  }
  
  .create-artefact-button {
    background-color: #79b8ff; /* Default color */
    transition: background-color 0.3s ease; /* Smooth transition for color change */
    border: none;
    outline: none;
    height: 38px; /* Set fixed height */
    width: 71px;
    
  }

  .create-artefact-button:hover:not(:disabled) {
    background-color: #0b419e; /* Darker shade on hover when enabled */
    border: none;
    outline: none;
    height: 38px; /* Set fixed height */
    width: 71px;
  }
  
  .create-artefact-button:disabled {
    background-color: #b6d4f7; /* Lighter shade for the disabled state */
    cursor: not-allowed; /* Optional: indicates it's not clickable */
    opacity: 0.6; /* Slight opacity to further indicate it's disabled */
    border: none;
    outline: none;
    height: 38px; /* Set fixed height */
    width: 71px;
  }

  @media (max-width: 768px) {

    .title-img-artefact{
      position: absolute;
      margin-left: -10px !important;
      margin-top: -90px !important;
      margin-bottom: 10px;
    }
    .artefact-upload {
      /* Reduce the width by 10% and the height by 5% */
      width: 360px !important;
      height: 100% !important;
      min-height: 350px !important;
      margin-top: -90px !important;
    }

    .artefact-form{
      /* height: 100%; */
      height: 300px !important;
      min-height: 300px !important;
      max-height: 300px !important;
    }
    .artefact-number{
      white-space: no-wrap !important;
      /* padding: 0px 0px !important; */
      /* width: 100% !important; */
      /* font-size: 12px !important; */

    }


    .remove-artefact-button{
      margin-left: -60px !important;
      margin-top: 0px !important;
    }
  }
  