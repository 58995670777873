html, body {margin: 0; height: 100%; overflow: hidden}

.flex-container {
  display: flex;
}

.mypage-contents{
  font-family: raleway;
  position: relative;
}

.empty-page-text {
  position: relative;
  text-align: center;
  margin-top: 17%;
}


@media (max-width: 768px) {
  .low-connection {
    position: 0 0 0 0;
    top: 68px !important;
    left: 50%;
    margin-left: -56px !important;
  }
  .low-connection.app-mobile {
    top: 112px !important;
  }
  }
