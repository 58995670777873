@import url('https://fonts.googleapis.com/css2?family=Raleway:ital,wght@0,200;0,300;0,400;1,200;1,300;1,400&display=swap');


.analytics-container {
    background: url('Gradient2.png') no-repeat center center fixed;
    background-size: cover;
    font-family: 'Raleway', sans-serif;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    padding-top: 50px;
  }
  
  .analytics-logo-container {
    text-align: center;
    margin-bottom: 20px;
  }
  

  .analytics-content {
    background: rgba(255, 255, 255, 0.9);
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
    width: 90%;
    max-width: 800px;
  }
  
  .analytics-content h1 {
    text-align: center;
    margin-bottom: 30px;
  }
  
  .analytics-content h2 {
    margin-bottom: 10px;
    font-weight: 400 !important;
  }
  
  .analytics-content p {
    margin-bottom: 20px;
  }

  .analytics-span {
    margin-bottom: 20px;
    font-weight: 400 !important;
    
  }
  