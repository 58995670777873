.collection-contents{
  font-family: raleway;
  position: relative;
}


.no-select {
  -webkit-touch-callout: none; /* Disable callout, e.g., phone numbers in Safari */
  -webkit-user-select: none;   /* Disable text selection in Safari */
  -khtml-user-select: none;    /* Disable text selection in Konqueror */
  -moz-user-select: none;      /* Disable text selection in Firefox */
  -ms-user-select: none;       /* Disable text selection in Internet Explorer/Edge */
  user-select: none;           /* Disable text selection in non-prefixed browsers */
}

.navbar-collection{
  z-index: 1 !important;
}



@media (max-width: 768px) {
.low-connection {
  position: 0 0 0 0;
  top: 68px !important;
  left: 50%;
  margin-left: -56px !important;
}
.low-connection.app-mobile {
  top: 112px !important;
}
}