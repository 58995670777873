.context-menu-box {
    display: flex;
    flex-direction: column;  /* This will make it vertical */
    background-color: white;
    border: 1px solid #ccc;
    border-radius: 5px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
    padding: 3px;
    width: calc(100% + 40px);
  }
  
  .context-menu-box button {
    padding: 5px;
    margin-bottom: 5px;
    border: none;
    background: none;
    cursor: pointer;
  }
  
  .context-menu-box button:last-child {
    margin-bottom: 0;
  }

  .delete-button {
    background-color: blue;
    z-index: -10;
    width: 100%;
    color: white; 
  }

.full-width-button {
  width: 100%;
  padding: 10px;
  transition: background-color 0.3s ease-in-out; /* smooth transition */
}

.full-width-button:hover {
  background-color: #ccc; 
}


/* Artefact.css or relevant CSS file */
.artefact-transition-enter {
  opacity: 0;
  transform: scale(0.9);
}
.artefact-transition-enter-active {
  opacity: 1;
  transform: scale(1);
  transition: opacity 300ms ease-in, transform 300ms ease-in;  
  /* Maybe try with 100ms or 200ms when built and on website */
  /* transition: opacity 300ms ease-in; */
}
.artefact-transition-exit {
  opacity: 1;
  transform: scale(1);
}
.artefact-transition-exit-active {
  opacity: 0;
  transform: scale(0.9);
  transition: opacity 300ms ease-out, transform 300ms ease-out;
  /* transition: opacity 300ms ease-out; */
}

/* .artefact-transition {
  transition: left 0.5s ease-in-out, top 0.5s ease-in-out;
} */
