@import url('https://fonts.googleapis.com/css2?family=Raleway:ital,wght@0,200;0,300;0,400;1,200;1,300;1,400&display=swap');


.close-account-dialog .modal-dialog {
    margin: auto; /* This will automatically center the modal */
    display: flex; /* Centering with flex */
    align-items: center; /* Center the content vertically */
    justify-content: center; /* Center the content horizontally */
}

.close-account-modal .modal-dialog-centered {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh; /* Ensure it takes up full height for proper centering */
}

.close-account-modal .modal-content {
    max-height: 290px !important;
    max-width: 470px !important;
    min-width: 470px !important;
    width: 470px !important;
    border-radius: 23px;
    overflow: hidden;
    font-family: 'Raleway', sans-serif;
    border: none; 
    background-color: rgb(205, 205, 205);
}

.close-account-modal .modal {
    display: flex !important;
    align-items: center;
    justify-content: center;
}





.close-account-modal .modal-header .btn-close {
    border-bottom: none !important;

    position: absolute;
    top: 25px;
    right: 25px;
    z-index: 1;
    background-color: transparent;
    border: none;
    font-size: 1.2rem;
    padding: 0;
}

.close-account-modal .modal-header {
    border-bottom: none !important;

    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 15px 20px 0 20px;

}

.close-account-modal .modal-header .modal-title {
    border-bottom: none !important;
    margin: auto;
    font-size: 1.7rem;

}

.modal-footer-close-account {
    border-top: none !important; 
    padding-top: 0 !important;
    display: flex;
    justify-content: center; 
    align-items: center;
    /* gap: 10px;  */
    /* margin-right: 70px; */

}


.modal-body-close-account {
    display: flex;
    justify-content: center;  
    align-items: center;    
    text-align: center;      
    padding: 0 30px !important;          
}




/* Disable all background interactions while the modal is open */
.disable-background {
  pointer-events: none; /* Disable all pointer events (clicks, etc.) */
  user-select: none;    /* Disable text selection */
  overflow: hidden;     /* Prevent scrolling */
}

.no-select {
    -webkit-touch-callout: none; /* Disable callout, e.g., phone numbers in Safari */
    -webkit-user-select: none;   /* Disable text selection in Safari */
    -khtml-user-select: none;    /* Disable text selection in Konqueror */
    -moz-user-select: none;      /* Disable text selection in Firefox */
    -ms-user-select: none;       /* Disable text selection in Internet Explorer/Edge */
    user-select: none;           /* Disable text selection in non-prefixed browsers */
  }
  