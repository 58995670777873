.valid-message {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    /* color: green; */
    /* color: #0AAE5D; */
    color: #0DAB48;
    text-align: center;
    margin-top: 87px;
    flex-shrink: 0;
    opacity: 0; /* Start hidden */
    visibility: hidden; /* Start hidden */
    transition: opacity 1s ease-in-out, visibility 0s linear 1s; /* Smooth transition for both */
    z-index: 100;
  }
  
  .valid-message.show {
    opacity: 1; /* Show with fade-in */
    visibility: visible; /* Make visible with no delay */
    transition: opacity 1s ease-in-out, visibility 0s linear 0s; /* Smooth transition for showing */
  }

  .valid-message.app-mobile {
    margin-top: 127px;
  }
  
  @media (min-width: 769px) and (max-width: 1791px) {
    .low-connection-home {
        position: absolute; /* Ensure consistency */
        top: 75px !important;
        left: 50%;
        margin-left: -67px !important;
    }
}


@media (max-width: 768px) {
    .url-text{
        /* margin-top: 10px !important; */
        transform: translate(0%, 180%) !important;
    }

    .low-connection-home {
      position: 0 0 0 0;
      /* top: 78px !important; */
      /* top: 55px !important; */
      top: 108px !important;

      left: 50%;
      margin-left: -67px !important;
    }
}
