@import url('https://fonts.googleapis.com/css2?family=Raleway:ital,wght@0,200;0,300;0,400;1,200;1,300;1,400&display=swap');


.analytics-container {
    background: url('Gradient2.png') no-repeat center center fixed;
    background-size: cover;
    font-family: 'Raleway', sans-serif;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    padding-top: 50px;
  }
  
  .analytics-logo-container {
    text-align: center;
    margin-bottom: 20px;
  }
  

  .terms-conditions-content {
    background: rgba(255, 255, 255, 0.9);
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
    width: 90vw; /* Set the width to 70% of the viewport width */
    margin: 0 auto; /* Center the div horizontally */
    max-width: 90%;
  }
  
  .terms-conditions-content h1 {
    text-align: center;
    margin-bottom: 30px;
  }
  
  .terms-conditions-content h2 {
    margin-bottom: 10px;
  }
  
  .terms-conditions-content p {
    margin-bottom: 20px;
  }
  

  @import url('https://fonts.googleapis.com/css2?family=Raleway:wght@400;700&display=swap');


.fade-in {
    opacity: 1;
  }
  
  .fade-out {
    opacity: 0;
  }
  

  @media (max-width: 768px) {
    .centered-box {
      left: 50% !important;
      top: 50% !important;
      transform: translate(-50%, -45%) scale(0.9) !important; /* Center and scale down the box */
      width: 90% !important; /* Adjust width to fit better on small screens */
      height: 420px !important; /* Adjust height to fit content */
      padding: 20px !important;
    }

    .grey-logo-icon {
      height: 20px; /* Adjust size if necessary */
      margin-right: -20px; /* Adjust margin to reduce space or overlap */
      flex: 0 0 auto; /* Do not grow or shrink */
  }

    .navbar {
      display: flex;
      justify-content: space-between;
      width: 85%;                
      padding: 10px 5px;         
      box-sizing: border-box;     
      column-gap: 5px !important; 
      
  }

  .navbar button {
      font-size: 1.0rem;          /* Adjust font size to ensure it fits */
      padding: 5px 6px;           /* Reduce padding to save space */
      flex: none;                 /* Disable flex grow to maintain button sizes */
      white-space: nowrap;        /* Ensure text does not wrap */
  }

  .navbar button:last-child {
    margin-right: 0;           /* Remove margin from the last button */
  }
  
    .centered-box-content {
      transform: scale(0.9); /* Scale down the contents slightly */
      transform-origin: top left; /* Ensure scaling starts from the top left */

    }
  
    .centered-box h2 span {
      font-size: 1.7rem !important; /* Reduce the font size of headings */
    }
  
    .centered-box p {
      font-size: 1.08rem; /* Reduce the font size of paragraphs */
    }

  
    .centered-box button {
      padding: 5px 10px; /* Reduce padding inside buttons */
      font-size: 0.9rem; /* Adjust button text size */
    }
    .centered-box img {
      transform: scale(0.65) translate(81px, 130px);
      transform-origin: center;
      margin-top: -50px;
    }
    .centered-box img:first-of-type {
      margin-right: -97px !important; /* Set the gap between the icon and the logo to 10px */
    }

    .home-features {
      transform: translate(-15px, 0px);
      width: calc(100% - 40px) !important; /* Increase width */
      height: 160px !important; /* Increase height */
      padding: 20px !important; /* Increase padding */
      font-size: 13px !important; /* Adjust font size for better readability */
    }
  }
  
  

  img {
    pointer-events: none;
  }



  @media (max-width: 768px) {
    .terms-conditions-logo-container .logo-container{
        position: fixed !important; /* Use fixed to keep it at the bottom on mobile */
        bottom: 10px !important;  /* Closer to the edge on mobile */
        left: 50% !important;
        transform: translate(-50%, 45%) !important;
        width: 100px !important;  /* Optional: Resize logo for smaller screens */
    }
}
