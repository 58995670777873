/* Base modal body styles */
.modal-body {
    height: 400px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 20px;
  }
  
  /* Styles specific to the artefact modal */
  .create-artefact-modal .modal-content {
    border-radius: 23px;
    -webkit-border-radius: 28px !important;
    max-height: 300px;
    height: 300px;
    max-width: 400px !important;
    width: 400px !important;
    transition: max-width 0.3s ease, height 0.3s ease;
  }
  
  /* Prevent text selection */
  .no-select {
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }
  
  /* Close button styling */
  .close-button {
    font-size: 12pt;
    margin-top: 10px;
    margin-right: 10px;
    position: absolute;
    top: 0;
    right: 0;
  }
  
  /* Center the form within the modal */
  .creation-form {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  /* Expanded form styles for Image type */
  .create-artefact-modal.create-artefact-form-expanded .modal-content {
    max-width: 700px !important; /* Adjust as needed */
    width: 700px !important;
    max-height: 500px !important;
    height: 500px !important;
    display: flex;
    justify-content: center;
    align-items: center;
    left: 50%;
    transform: translateX(-50%);
  }
  
  /* Responsive adjustments */
  @media (max-width: 768px) {
    .create-artefact-modal .modal-dialog {
      max-width: 400px !important;
      width: 400px !important;
      max-height: 170px !important;
      height: auto !important;
      margin: auto;
    }
  
    .create-artefact-modal.create-artefact-form-expanded .modal-content {
      max-width: 100% !important;
      width: 90% !important;
      max-height: 500px !important;
      height: auto !important;
    }
  
    .create-artefact-modal .modal-content {
      height: 330px !important;
    }
  
    .create-artefact-modal .modal-body {
      height: auto;
      padding: 20px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
  
    .col-and-artef-buttons {
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 10px;
      width: 100%;
      margin-top: 10px;
    }
  
    .create-artefact-modal .col-and-artef-buttons .image-button,
    .create-artefact-modal .col-and-artef-buttons .text-button {
      justify-self: end;
    }
  
    .create-artefact-modal .col-and-artef-buttons .website-button,
    .create-artefact-modal .col-and-artef-buttons .pdf-button {
      justify-self: start;
    }
  
    .create-artefact-modal .col-and-artef-buttons button {
      width: 100%;
      height: 60px;
    }
  }
  
  /* Expanded form-specific styles */
  .create-artefact-form-modal {
    max-width: 600px !important;
    height: 500px !important;
    max-width: 600px !important;
    width: 600px !important;
    padding: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  /* Additional styles for create-artefact-form-expanded */
  .create-artefact-modal.create-artefact-form-expanded .modal-dialog {
    max-width: 800px !important; /* Adjust as needed */
    width: 800px !important;
    max-height: 600px !important;
    height: 600px !important;
  }


  /* .create-artefact-modal.create-artefact-form-expanded .modal-content {
    max-width: 400px !important; 
    width: 400px !important;
    max-height: 500px !important;
    height: 500px !important;
    display: flex;
    justify-content: center;
    align-items: center;
    left: 25%;
    transform: translateX(-50%);
  } */

  .create-artefact-modal.create-artefact-form-expanded .modal-content {
    max-width: 660px !important; 
    width: 660px !important;
    max-height: 500px !important;
    height: 500px !important;
    display: flex;
    justify-content: center;
    align-items: center;
    /* left: 50% !important; */
    /* transform: translateX(-50%) !important; */
  }
  
  
  @media (max-width: 768px) {
   

    .create-artefact-modal.create-artefact-form-expanded .modal-content {
      max-width: 400px !important;
      width: 400px !important;
      max-height: 500px !important;
      height: 500px !important;
      display: flex;
      justify-content: center;
      align-items: center;
      left: 26.7% !important;
    }
    .create-artefact-modal.create-artefact-form-expanded .close-button {
      margin-right: 115px !important;
    }
  }