.verify-container {
    /* background-image: url('Gradient2.png'); */
    width: 100vw;
    height: 100vh;
    position: absolute;
    background-size: cover;
}


.no-select {
    -webkit-touch-callout: none; /* Disable callout, e.g., phone numbers in Safari */
    -webkit-user-select: none;   /* Disable text selection in Safari */
    -khtml-user-select: none;    /* Disable text selection in Konqueror */
    -moz-user-select: none;      /* Disable text selection in Firefox */
    -ms-user-select: none;       /* Disable text selection in Internet Explorer/Edge */
    user-select: none;           /* Disable text selection in non-prefixed browsers */
  }

  .digit-six.form-control {
    /* height: 70px !important ; */
    padding-top: 15px !important;
  }



  @media (max-width: 768px) {
    .verify-email-title{
        font-size: 31px !important;
    }
    .verify-email-subtitle{
        font-size: 17px !important;
    }
    .resend-code-text{
        margin-top: 23px !important;
        font-size: 15.2px !important;
    }
   .verify-email-showcase-logo {
    white-space: nowrap !important;
    scale: 0.7 !important;
    margin-bottom: -42px !important;
   }
   .verify-email-form{
        height: 460px !important;
        margin-top: 80px !important;

        padding: 40px 20px 20px 20px !important;
        /* min-height: 400px !important; */
        /* max-height: 400px !important; */

    }  
    .any-issues-text{
        white-space: nowrap !important;
    }
}

.verify-container.app-mobile-landscape-verify {
        scale:  0.75 !important;
        margin-top: 0px !important;
        display: flex !important;        /* override inline style */
  flex-direction: row !important;  /* or row-reverse if desired */
  align-items: center;
  justify-content: center;
  flex-direction: row-reverse !important;
}
.verify-email-showcase-logo.app-mobile-landscape-verify{
    margin-left: 30px !important;
    margin-top: -10px !important;
    scale: 0.9 !important;
}

.background-image-verify.app-mobile-landscape-verify{
    margin-top: 20px !important;
}

.verify-email-form.app-mobile-landscape-verify {
    margin-top: -3% !important;
    height: 495px !important;

} 