.no-select {
  -webkit-touch-callout: none; /* Disable callout, e.g., phone numbers in Safari */
  -webkit-user-select: none;   /* Disable text selection in Safari */
  -khtml-user-select: none;    /* Disable text selection in Konqueror */
  -moz-user-select: none;      /* Disable text selection in Firefox */
  -ms-user-select: none;       /* Disable text selection in Internet Explorer/Edge */
  user-select: none;           /* Disable text selection in non-prefixed browsers */
}



.friends-modal {
    text-align: center;
    position: absolute;
    top: 0;
    width: 85%;
    font-family: Raleway;
    margin-bottom: 0px;
    font-size: 25px;
    color: #9D9D9D;
    margin-top: 13px;
    /* height: 300px; */
}

.modal-content


.friends-modal {
  height: 200px; /* Increased from 300px to 400px */
}

.friends-modal-container .modal-content {
  height: 450px; /* Increased from 500px to 600px */
  max-height: 600px; /* Increased from 500px to 600px */
}

.friends-modal-box.modal-body {
  height: 500px; /* Increased from 400px to 500px */
  max-height: 500px; /* Increased from 400px to 500px */
}


.friends-modal-box.modal-body {
    height: 400px;
    max-height: 400px;
}

.friends-divider {
    margin-top: -15px;
}

.nav-item-hover {
    cursor: pointer;
}


.scrollable-area {
margin-top: 10px;
max-height: 270px;  /* Adjust height as needed */
overflow-y: auto;
}



.scrollable-area::-webkit-scrollbar {
    width: 6px;
    height: 8px; 
  }
  
  .scrollable-area::-webkit-scrollbar-thumb {
    background: #DDD;
    border-radius: 4px;
  }
  
  /* When hover or active */
  .scrollable-area::-webkit-scrollbar-thumb:hover,
  .scrollable-area::-webkit-scrollbar-thumb:active {
    background: #BBB;
  }
  
  /* Firefox */
  .scrollable-area {
    scrollbar-width: thin;
    scrollbar-color: #DDD #f1f1f1; /* thumb and track color */
  }


  @media (max-width: 768px) {
    .notification-dot-requests {
      /* position: "fixed" !important; */
    }
}
