.artefacts-form {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 0 !important; /* Removes default margin */
}

.input-box {
  width: 250px; /* Adjust the width as necessary */
  display: flex;
  flex-direction: column;
  align-items: center;
}

* {
  -webkit-text-size-adjust: none;
   text-size-adjust: none;
 }

@media (max-width: 768px) {

  .form-input {
    margin-top: 50vh;
  }

  
    /* Move the Text Heading down by 5px */
    .artefacts-text-form-heading {
      /* margin-top: -18px !important; */
      /* margin-top: -22px !important; */
      margin-top: -59px !important;


    }
    .artefacts-form-heading {
      margin-top: -15px !important;
      margin-bottom: 0px !important; 
    }
  
    /* Move the Quill editor up by 5px */
    .website-artefacts-form .ql-container {
      margin-top: 0px !important; /* Decrease margin-top by 5px */
    }

  
    /* Move the button up by 5px */
    .create-button-text-artefact {
      margin-top: 37px !important; /* Decrease margin-top by 5px */
      margin-bottom: 0px !important; /* Decrease margin-bottom by 5px */
    }
  }
  