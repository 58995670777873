.search-box {
    position: absolute;
    top: 0;
    margin-top: 35px;
    width: 70%;
}

.search-title {
    font-size: 25px;
    text-align: center;
    margin-top: -25px;
    margin-bottom: 9px;
    color: #9D9D9D;
    font-family: Raleway;
}

.explore-modal-box.modal-body {
    height: 400px;
    max-height: 400px;
    font-family: Raleway;
}

.explore-modal-divider {
    margin: 5% -8%;
}

.search-results {
    overflow-y: auto;
}


.search-results::-webkit-scrollbar {
width: 8px;
}

.search-results::-webkit-scrollbar-thumb {
background: #888;
border-radius: 4px;
}

.search-results::-webkit-scrollbar-thumb:hover,
.search-results::-webkit-scrollbar-thumb:active {
background: #555;
}

.search-results {
scrollbar-width: thin;
scrollbar-color: #888 #f1f1f1;
}
