/* .signup-container {
    background-image: url('Gradient2.png');
    width: 100vw;
    height: 100vh;
    position: absolute;
    background-size: cover;
}
 */

.no-select {
  -webkit-touch-callout: none; /* Disable callout, e.g., phone numbers in Safari */
  -webkit-user-select: none;   /* Disable text selection in Safari */
  -khtml-user-select: none;    /* Disable text selection in Konqueror */
  -moz-user-select: none;      /* Disable text selection in Firefox */
  -ms-user-select: none;       /* Disable text selection in Internet Explorer/Edge */
  user-select: none;           /* Disable text selection in non-prefixed browsers */
}


.signup-box {
    font-family: raleway;
    max-width: 500px;
    min-width: 300px !important;
    border: 3px;
    border-radius: 19px;
    font-family: 'Raleway', sans-serif;
    padding: 2%;
    background: rgba(200,200,200, 0.5);
    /* backdrop-filter: blur(2px); */
    margin-top: 7.5%;
    margin-left: 9%;
}

.signup-button-container {
    display:flex;
    justify-content:center;
    align-items: center;
}

.signup-button {
    width: 140px;
}

.signup-content {
    display: flex;
    align-items: center;
    gap: 20px; /* Space between the signup box and the logo */
  }


  .logo-icon{
    height: 80px;
    width: auto;
  }

  .logo-showcase {
    height: 120px;
    width: auto;
    margin-left: 8px;
  }

  .required-asterisk {
    color: red;
    margin-left: 1px;
    margin-top: -7px;
    font-size: 25px;
    vertical-align: top;
  }

  .custom-font-size {
    font-size: 13px;
  }

  .terms-checkbox-container {
    display: flex;
    justify-content: center; /* Center the checkbox and label horizontally */
    align-items: center; /* Align them vertically (optional, for better alignment) */
  }

  .form-floating > label::after {
    background-color: transparent !important;
}
  
  @media (max-width: 768px) {
/* @media (max-width: 1200px) { */

    .signup-container{
      margin-top: 0px !important;
    }
    .signup-content {
      flex-direction: column; /* Stack children vertically */
      align-items: center; /* Center-align the items */

    }
  
    .signup-box {

      position: relative; /* Ensure the adjustments work relative to its original position */
      width: 92%; /* Adjust width for mobile */
      transform: translate(-55px, -100px); /* Move the container left by 70px and up by 210px */
      text-align: center; /* Center-align text inside the container */
      height: 510px; /* Increase the height of the container */
      padding-top: 20px; /* Add some padding to the top */
    }
  
    .logo-container {
      
      order: -1; /* Place logo above the signup container */
      transform: translate(-18px, -63px); /* Move the logo up by 60px */
      margin-bottom: 20px; /* Add some space below the logo */
      margin-top: 100px !important;
    }
  
    .logo-icon {
      height: 50px; /* Smaller logo icon for mobile */
      transform: translateY(30px);

    }
  
    .logo-showcase {
      height: 80px; /* Smaller logo showcase for mobile */
      margin-left: 0; /* Center the logo on mobile */
      transform: translateY(30px);
    }

    /* Adjust the Full Name form part specifically */
  .signup-box .form-floating#fullNameContainer {
    flex: none; /* Ensure this form part doesn't shrink more than it should */
    width: 100%; /* Use the full available width on mobile */
  }

  .signup-box .form-floating#fullNameContainer label {
    /* font-size: 0.85rem !important; */
    white-space: nowrap; /* Prevent the label text from wrapping */
    /* line-height: 1.95 !important; */
  }

  .signup-box .form-floating#fullNameContainer input {
    /* font-size: 0.9rem !important;  */
  }
  .terms-checkbox-container {
    display: flex;
    justify-content: center; /* Center the checkbox and label horizontally */
    align-items: center; /* Align them vertically (optional, for better alignment) */
  }
  
  .terms-checkbox .form-check-label {
    display: flex;
    align-items: center;
  }
  
  .terms-checkbox .form-check-input {
    margin-right: 10px; /* Maintain a consistent 10px gap between the checkbox and the text */
  }
  .back-icon-signup{
    margin-left: 10px !important;
    margin-top: -6px !important;
  }
  }
  


  @media (min-width: 768px) and (max-width: 1170px) {
 
    .low-connection-signup.app-mobile-landscape-signup{
        top: 12% !important;
        left: 63% !important;
      }
}