.modal-body{
    height: 20vh;
    display:flex;
    justify-content:center;
    align-items: center;
    border-radius: 20px;
}

.create-collection-modal .modal-content {
    border-radius: 23px;
    -webkit-border-radius: 18px !important;
    height: 200px; 
    overflow: auto; 
    min-height: 240px;
    max-height: 240px;
    min-width: 350px;
    max-width: 350px;
}

.create-artefact-modal .modal-dialog {
    max-width: 28%; /* Adjust the width as needed */
    max-height: 400px;
    min-width: 400px;
  }

.close-button {
    font-size: 12pt;
    margin-top: 10px;
    margin-right: 10px;
    position:absolute;
    top:0;
    right:0;
}

.creation-form {
    display:flex;
    justify-content:center;
    align-items: center;
}

.This {
    border-radius: 19px;
}


@media (max-width: 768px) {
    .create-collection-modal .modal-dialog {
        max-width: 340px !important; 
        width: 340px !important; 
        max-height: 150px !important; 
        height: auto !important;
        margin: auto;
      }
    
      .create-collection-modal .modal-content {
        height: 220px !important;
      }

      .creation-form {
        display: flex;
        flex-direction: column;
        align-items: center; 
        justify-content: center; 
    }

    .create-button-container {
        display: flex;
     transform: translateX(calc(-5% + 0vw)) !important; 
    }

    .create-button {
        margin: 0 auto;
    }
}