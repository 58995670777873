@import url('https://fonts.googleapis.com/css2?family=Raleway:wght@400;700&display=swap');

.required-asterisk {
    color: red;
    margin-left: 1px;
    margin-top: -7px;
    font-size: 25px;
    vertical-align: top;
  }

  .no-select {
    -webkit-touch-callout: none; /* Disable callout, e.g., phone numbers in Safari */
    -webkit-user-select: none;   /* Disable text selection in Safari */
    -khtml-user-select: none;    /* Disable text selection in Konqueror */
    -moz-user-select: none;      /* Disable text selection in Firefox */
    -ms-user-select: none;       /* Disable text selection in Internet Explorer/Edge */
    user-select: none;           /* Disable text selection in non-prefixed browsers */
  }
  