.no-select {
  -webkit-touch-callout: none; /* Disable callout, e.g., phone numbers in Safari */
  -webkit-user-select: none;   /* Disable text selection in Safari */
  -khtml-user-select: none;    /* Disable text selection in Konqueror */
  -moz-user-select: none;      /* Disable text selection in Firefox */
  -ms-user-select: none;       /* Disable text selection in Internet Explorer/Edge */
  user-select: none;           /* Disable text selection in non-prefixed browsers */
}



@media (max-width: 768px) {
    .search-box {
      width: 60% !important; /* Adjust to 80% for mobile view */
    }
  }