
   .breadcrumb-container {
    position: relative;
    width: 100%; /* You can adjust this based on your layout */
  }
  
  .breadcrumb-transition {
    position: fixed;
    transition: opacity 0.5s ease-in;
  }
  
  .breadcrumb-visible {
    opacity: 1;
    visibility: visible;
  }
  
  .breadcrumb-hidden {
    opacity: 0;
    visibility: hidden;
    pointer-events: none; /* Prevent interaction when hidden */
  }

  .breadcrumb-text-wrapper {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    position: relative;
  }

  /* .breadcrumb-transition.collapsed {
    max-width: 127px;
    overflow: hidden !important;
  }
  
  .breadcrumb-transition.expanded {
    max-width: 280px;
    overflow: hidden !important;
  }
   */

   .breadcrumb-transition.collapsed {
    max-width: 127px;
  }
  
  .breadcrumb-transition.expanded {
    max-width: 280px;
  }
  
  /* Apply overflow: hidden to .breadcrumb-text-wrapper */
  .breadcrumb-text-wrapper {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    display: inline-block;
  }
  
  .breadcrumb-text-wrapper.collapsed {
    max-width: 127px;
  }
  
  .breadcrumb-text-wrapper.expanded {
    max-width: 280px;
  }
  


/* Tooltip container */
.breadcrumb-tooltip {
  position: relative;
  display: inline-block;
  cursor: pointer;
  z-index: 1;
}



.breadcrumb-tooltip .tooltip-text {
  visibility: hidden;
  background-color: #ccc;
  color: #000;
  text-align: center;
  border-radius: 5px;
  padding: 5px;
  font-size: 13px;
  position: absolute;
  /* z-index: 1; */
  z-index: 999;
  top: 100%; /* Position the tooltip below the cursor */
  left: 50%; /* Center the tooltip */
  transform: translateX(-50%) translateY(10px); /* Center the tooltip and add some space below the cursor */
  white-space: nowrap;
  opacity: 0;
  /* transition: opacity 0.3s, visibility 0.3s, transform 0.3s; */
  transition: opacity 0.3s 0.7s, visibility 0.1s 0.7s, transform 0.6s;
  cursor: pointer;
}

.tooltip-item {
  text-decoration: underline;
}


.breadcrumb-tooltip:hover .tooltip-text {
  visibility: visible;
  opacity: 1;
  transform: translateX(-50%) translateY(0); /* Slide up into position when visible */
}