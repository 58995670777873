.no-select {
  -webkit-touch-callout: none; /* Disable callout, e.g., phone numbers in Safari */
  -webkit-user-select: none;   /* Disable text selection in Safari/Chrome */
  -khtml-user-select: none;    /* Disable text selection in Konqueror */
  -moz-user-select: none;      /* Disable text selection in Firefox */
  -ms-user-select: none;       /* Disable text selection in Internet Explorer/Edge */
  user-select: none;           /* Disable text selection in non-prefixed browsers */
}

.clamped-text {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  line-height: 1.2em; /* Adjust as per your font size */
  /* width: 100%; */
}



/* Fallback for unsupported browsers */
.no-clamp {
  overflow: hidden;
  text-overflow: ellipsis;
  max-height: 2.4em; /* Same as above */
  line-height: 1.2em;
}






/* Option 1: normal */
.profile-box-container {
    position: absolute;
    /* top: 50%; */
    /* left: 50%; */
    transform: translate(-50%, -50%);
    width: 280px;
    height: 160px;
    background-color: rgba(212 , 211, 209, 0.2);


    backdrop-filter: blur(10px);
    padding: 10px;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
    /* box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.2);  */
    transition: box-shadow 0.3s ease-in-out, transform 0.3s ease-in-out; 
  }

  .profile-box-container::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 10px;
    background: linear-gradient(270deg, #ccc, #fff, #bbb);
    background-size: 600% 600%;
    animation: GradientAnimation 9s ease infinite;
    opacity: 0.4;
    z-index: -10; 
  }

  @keyframes GradientAnimation {
    0% { background-position: 0% 50%; }
    50% { background-position: 100% 50%; }
    100% { background-position: 0% 50%; }
  }
  
  .profile-box-container:hover {
    box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.2); 
  }
  

/* Option 2: diagonal sheen */

  /* .profile-box-container {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 280px;
    height: 160px;
    background-color: rgba(212, 211, 209, 0.5);
    backdrop-filter: blur(10px);
    padding: 10px;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0);
    transition: box-shadow 0.3s ease-in-out, transform 0.3s ease-in-out; 
    background: linear-gradient(145deg, #e2e2e2, #f7f7f7, #d4d4d4, #fff);
    background-size: 250% 250%;
    animation: metallicShine 20s ease infinite;
    /* animation-play-state: paused; */
    /* border: 1px solid rgba(255, 255, 255, 0.2);
  } */
  
  .profile-box-container:hover {
    box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.2); 
  }
  
  /* @keyframes metallicShine {
    0% {
      background-position: 0% 50%;
    }
    50% {
      background-position: 100% 50%;
    }
    100% {
      background-position: 0% 50%;
    }
    100% {
      background-position: 0% 50%;
    }
  } */

  /* .profile-box-container {
    --shine-deg: -45deg; 
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 280px;
    height: 160px;
    background-color: rgba(212 , 211, 209, 0.5);
    backdrop-filter: blur(10px);
    padding: 10px;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
   
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0);
    transition: box-shadow background-position 0s ease, transform 0.3s ease-in-out;

    background-repeat: no-repeat;
    background-position: -100% 0, 0 0;
    background-image: linear-gradient(
        var(--shine-deg),
        transparent 20%,
        transparent 40%,
        rgba(68, 68, 68, 0.1) 50%,
        rgba(68, 68, 68, 0.1) 55%,
        transparent 70%,
        transparent 100%
    );
    background-size: 250% 250%, 100% 100%;
    overflow: hidden;
    
}

.profile-box-container:hover {
    background-position: 200% 0, 0 0;
    transition-duration: 1.5s;
} */
 



  .profile-box {
    color: black;
    margin: 0;
    font-size: 30px;
    text-align: center;
    margin-top: 50px;
  }
  
  .profile-box-container.expanded {
    transform: scale(2) translate(-70px, -40px);
    transform-origin: center center;
  }
  

  .additional-text {
    margin-left: -60px;
    margin-top: 5px;
    font-size: 15px;
    opacity: 0;
    transition: opacity 0.5s ease-in-out, font-size 0.5s ease-in-out;
  }
  
  .fade-in {
    opacity: 1;
  }
  

  .fade-in-description {
    opacity: 0;
    transition: opacity 0.3s ease-in-out;
  }
  
  .show-description {
    opacity: 1;
  }
  

  .arrow-button-margin{
    text-align: center;
    margin-top: -20px;
}

.arrow-key-boxes {
    background: none;
    height: 24px;
    width: 38px;
    display:flex;
    justify-content:center;
    align-items: center;
    border-radius: 5px;
}


.arrow-keys {
    float: left;
    margin-top: -36px;
    margin-left: -13px;
    position: relative;
    top: 26px;
    left: 8px;
    color: #9D9D9D;
}

.arrow-key-boxes.btn.btn-light {
    background-color: #EAEAEA;
}


.arrow-key-boxes:hover {
  background-color: rgba(200, 200, 200, 0.5);  
  border-color: #ccc; 
  cursor: pointer;  
}

.arrow-key-boxes.disabled:hover {
  background-color: transparent;  /* Disable hover background change */
  border-color: transparent;      /* Disable hover border change */
  cursor: not-allowed;            /* Keep the cursor as not-allowed */
}



.sharelink-copied-message {
  position: absolute;
  top: 50px;
  left: 50%;
  transform: translateX(-50%);
  background-color: #333;
  color: white;
  /* padding: 8px 12px; */
  border-radius: 4px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  opacity: 0;
  animation: sharelinkFadeInOut 1.1s forwards;
}

@keyframes sharelinkFadeInOut {
  0% {
    opacity: 0;
  }
  10% {
    opacity: 1;
  }
  80% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

.interactive-link {
  background-color: transparent;
}

.interactive-link:hover {
  background-color: rgba(200, 200, 200, 0.5);
}


.sharelink-fade-in {
  opacity: 1;
  visibility: visible;
  transition: opacity 1s ease-in-out, visibility 1s ease-in-out;
}

.sharelink-fade-out {
  opacity: 0;
  visibility: hidden;
  transition: opacity 1s ease-in-out, visibility 1s ease-in-out;
}


.username-fade-in {
  opacity: 0;
  transition: opacity 0.8s ease-in;
}

.username-fade-in.show {
  opacity: 1;
  transition: opacity 0.4s ease-out;
}

.sharelink-button-container .tooltip-text {
  visibility: hidden;
  width: 190px;
  background-color: #f0f0f0;
  color: #000;
  text-align: center;
  border-radius: 6px;
  padding: 5px;
  position: absolute;
  z-index: 1;
  bottom: 100%; /* Position the tooltip above the button */
  left: 50%;
  margin-left: -90px;
  opacity: 0;
  transition: opacity 0.3s ease, visibility 0.3s ease; /* Transition for opacity and visibility */
  transition-delay: 0s; /* No delay on hiding */
  font-size: 8px;
  margin-bottom: 5px;
}

.sharelink-button-container button:hover .tooltip-text {
  visibility: visible;
  opacity: 1;
  transition-delay: 1s; /* Delay of 1 second before showing */
}



@media (hover: none) {
  /* Ensure it looks like a button on mobile where hover isn't available */
  .interactive-link {
    background-color: #e0e0e0
  }
}


