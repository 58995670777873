.no-select {
    -webkit-touch-callout: none; /* Disable callout, e.g., phone numbers in Safari */
    -webkit-user-select: none;   /* Disable text selection in Safari */
    -khtml-user-select: none;    /* Disable text selection in Konqueror */
    -moz-user-select: none;      /* Disable text selection in Firefox */
    -ms-user-select: none;       /* Disable text selection in Internet Explorer/Edge */
    user-select: none;           /* Disable text selection in non-prefixed browsers */
  }
  

  .grey-logo {
    opacity: 1;
    z-index: 1;
  }
  
  /* Colored logo is hidden by default */
  .colored-logo {
    opacity: 0;
    z-index: 2;
  }
  
  .logo-container-home-nav{
    margin-right: 11px !important;
  }

  /* On hover, fade out the grey logo and fade in the colored logo */
  .logo-container-home-nav:hover .grey-logo {
    opacity: 1;
    transition: opacity 0.5s, visibility 0s 0.5s;
   /* visibility: hidden; */
  }
  
  .logo-container-home-nav:hover .colored-logo {
    transition: opacity 0.5s, visibility 0.5s;
    opacity: 1;
    /* visibility: visible; */
  }



  /* @media (max-width: 768px) { */
  @media (max-width: 1000px) {

    .home-navbar-container, .home-navbar-nav {
      width: 288px !important;
      min-width: 288px !important;
      max-width: 288px !important;
      flex-wrap: nowrap !important;
      text-wrap: nowrap !important;
    }
    .grey-logo{
      margin-right: -18px !important;
      margin-left: 15px !important;
    }
    .colored-logo{
      margin-left: -9px !important;
      margin-right: -23px !important;
    }
  }