body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/* html,body{
  -webkit-overflow-scrolling : touch !important;
  overflow: auto !important;
} */

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


.form-floating > .form-label {
  font-size: 18px !important; /* Adjust as needed */
  line-height: 1.3; /* Ensure the spacing looks good */
  font-family: 'Raleway', sans-serif; /* Ensure it uses your desired font */
  /* margin-top: -3px; */
}