.modal-body{
    height: 200px;
    display:flex;
    justify-content:center;
    align-items: center;
    border-radius: 20px;
}

.choose-type-modal .modal-content {
    -webkit-border-radius: 30px !important;
    max-height: 300px !important;
    height: 300px !important;
    border-radius: 23px;
    overflow: auto; 
    max-width: 400px;
}

.choose-type-modal .modal-dialog {
    max-width: 28%; /* Adjust the width as needed */
    max-height: 40vh;
    max-width: 400px;
  }

.close-button {
    font-size: 12pt;
    margin-top: 10px;
    margin-right: 10px;
    position:absolute;
    top:0;
    right:0;
}

.plus-collection-button {
    width: 250px;
    height: 60px;
}

.plus-artefacts-button {
    height: 60px;
}


@media (max-width: 768px) {
    .choose-type-modal .modal-dialog {
        max-width: 350px; 
        width: 100%; 
        margin: auto; 
        display: flex; 
        justify-content: center;
        align-items: center;
    }

    .choose-type-modal .modal.show .modal-dialog {
        margin: auto;
    }

    .choose-type-modal .col-and-artef-buttons {
        display: flex !important;
        flex-direction: column !important; 
        align-items: center !important;
        width: 100% !important; 
    }
 
    .modal-body .plus-collection-button, 
    .modal-body .plus-artefacts-button {
        width: 100% !important;
        max-width: 250px !important; 
        margin-bottom: 10px !important; 
    }

    .modal-body {
        padding: 20px !important; 
    }
}
