.no-select {
  -webkit-touch-callout: none; /* Disable callout, e.g., phone numbers in Safari */
  -webkit-user-select: none;   /* Disable text selection in Safari */
  -khtml-user-select: none;    /* Disable text selection in Konqueror */
  -moz-user-select: none;      /* Disable text selection in Firefox */
  -ms-user-select: none;       /* Disable text selection in Internet Explorer/Edge */
  user-select: none;           /* Disable text selection in non-prefixed browsers */
}


.website-artefact {
    width: auto;
    height: auto;
    /* background-color: blue; */
    /* color: black; */
    max-width: "350px";
    max-height: "300px";
    width: "auto";
    height: "auto";
    border-radius: "10px";
    box-shadow: "0px 2px 6px rgba(0, 0, 0, 0.1)";
    transition: "top 0.3s ease, left 0.3s ease";
  }

  .image-style {
    cursor: pointer !important;
  }

  .fade-out-text {
    display: inline-block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    mask-image: linear-gradient(to right, black, black calc(100% - 40px), transparent);
    -webkit-mask-image: linear-gradient(to right, black, black calc(100% - 40px), transparent);
  }

  .fading-text-explore-artefact, .artefact-faded-name {
    -webkit-background-clip: text !important;
  }

  .gradient-text {
    /* Fallback for browsers that do not support background-clip */
    color: black;
  
    /* Apply background clip */
    background: linear-gradient(to right, black 50%, rgba(0, 0, 0, 0) 90%);
    -webkit-background-clip: text;
    -moz-background-clip: text; /* For older versions of Firefox */
    background-clip: text;
  
    /* Ensure the text is transparent to show the gradient */
    -webkit-text-fill-color: transparent;
    color: transparent; /* Fallback for non-webkit browsers */
  }

  
  .e-pv-viewer-container {
    overflow: hidden !important;
    background-color: aqua !important;
    color: chartreuse !important;
    background-color: rgb(119, 249, 238);
}

.e-pdfviewer .e-pv-thumbnail-view-icon{
  color: rgb(24, 182, 231);
}
.e-pdfviewer .e-pv-thumbnail-view-icon:hover{
  color: rgb(226, 76, 11);
}

.fade-in {
  transition: opacity 0.5s, visibility 0.5s;
  opacity: 1;
  visibility: visible;
}

.fade-out {
  transition: opacity 0.5s, visibility 0s 0.5s;
   opacity: 0;
  visibility: hidden;
}

.text-box {
  position: relative;
  width: 360px;
  overflow: hidden;
}

.text-name {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis; /* Optional: Adds an ellipsis if you want to indicate text is clipped */
}



.text-box::after {
  content: '';
  position: absolute;
  top: 0;
  right: 0;
  width: var(--gradient-width); /* Use the CSS variable */
  /* width: 80px; */
  margin-top: 5px;
  height: 100%;
  background: linear-gradient(to right, rgba(255, 255, 255, 0), rgba(255, 255, 255, 1));
  pointer-events: none;
  /* transition: opacity 0.25s ease;  */
  transition: opacity 0.25s ease, width 0.25s ease;
  /* margin-right: 20px; */
}

.text-box.hide-gradient::after {
  opacity: 0; /* This will hide the gradient effect */
}



/* .artefact-fade-in {
  animation: fadeIn 0.7s ease-out; 
}

.artefact-fade-out {
  animation: fadeOut 0.7s ease-out;
}

@keyframes fadeIn {
  from { opacity: 0.1; }
  to { opacity: 1; }
}

@keyframes fadeOut {
  from { opacity: 1; }
  to { opacity: 0.1; }
} */


.artefact-fade-in {
  animation: fadeIn 0.7s ease-out; 
}

.artefact-fade-out {
  animation: fadeOut 0.7s ease-out;
}

@keyframes fadeIn {
  from { opacity: 0.1; }
  to { opacity: 1; }
}

@keyframes fadeOut {
  from { opacity: 1; }
  to { opacity: 0; }
}

.fade-in-fullscreen {
  opacity: 1;
  visibility: visible;
  transition: opacity 0.4s ease-in-out, visibility 0.4s ease-in-out;
}

.fade-out-fullscreen {
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.4s ease-in-out, visibility 0.4s ease-in-out;
}

/* Add these styles to your CSS file (e.g., Artefact.css) */
.fade-in-url {
  opacity: 1;
  visibility: visible;
  transition: opacity 0.4s ease-in-out, visibility 0.4s ease-in-out;
}

.fade-out-url {
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.4s ease-in-out, visibility 0.4s ease-in-out;
}

/* .url-popup {
  max-width: 200ch; /* Adjust to the width you need for 500 characters */
  /* word-break: break-all;  */
  /* white-space: normal;  */
  /* overflow-wrap: break-word; */
/* } */

.custom-quill-editor .ql-container {
  min-height: 200px; /* Or any height you want to start with */
  height: auto;      /* Allow it to grow based on content */
}

.custom-quill-editor .ql-editor {
  min-height: 150px; /* Ensures that the text area has a minimum height */
  height: auto;      /* Allow it to expand as necessary */
}


.ql-editor {
  font-family: 'Raleway', sans-serif;
  font-size: 16px; /* You can adjust the default font size */
  line-height: 1.6; /* Adjust line-height if needed */
}

.ql-editor h5 {
  font-size: 23px !important; /* Adjust the size for heading 5 */
}

.ql-editor h1,
.ql-editor h2,
.ql-editor h3,
.ql-editor h4,
.ql-editor h5,
.ql-editor h6 {
  font-family: 'Raleway', sans-serif !important; /* Apply Raleway font */
}

.ql-snow .ql-picker.ql-header .ql-picker-label,
.ql-snow .ql-picker.ql-header .ql-picker-item {
  font-family: 'Raleway', sans-serif; /* Apply Raleway font */
}

.ql-snow .ql-picker.ql-header .ql-picker-item[data-value="1"] {
  font-size: 36px; /* For H1 */
}

.ql-snow .ql-picker.ql-header .ql-picker-item[data-value="2"] {
  font-size: 30px; /* For H2 */
}

.ql-snow .ql-picker.ql-header .ql-picker-item[data-value="3"] {
  font-size: 24px; /* For H3 */
}

.ql-snow .ql-picker.ql-header .ql-picker-item[data-value="4"] {
  font-size: 20px; /* For H4 */
}

.ql-snow .ql-picker.ql-header .ql-picker-item[data-value="5"] {
  font-size: 18px; /* For H5 */
}

.ql-snow .ql-picker.ql-header .ql-picker-item[data-value="normal"] {
  font-size: 8px; /* For Normal text */
}

.ql-editor ol, .ql-editor ul {
  padding-left: 0; /* Remove the left padding */
  margin-left: 0; /* Remove left margin */
}

.ql-editor li {
  padding-left: 0; /* Remove padding from list items */
}

.select {
  display: none !important;
  visibility: hidden !important;
}

select.ql-header {
  display: none !important;
}
/* 
.artefact-name-background {
  z-index: -100 !important;
} */


.artefact-name-background {
  opacity: 0 !important;
  transition: opacity 0.8s ease-in !important;
}

.artefact-name-background.show {
  opacity: 1 !important;
  transition: opacity 0.4s ease-out !important;
}

.artefact-faded-name-wrapper {
  max-height: 20px;
  opacity: 0.5;
  overflow: hidden;
  transition: max-height 0.5s ease-in-out, opacity 0.5s ease-in-out;
}

.artefact-faded-name-wrapper.expanded {
  max-height: 500px; /* Set a reasonable height for expanded state */
  opacity: 1;
}

.artefact-icon {
  opacity: 0;
  visibility: hidden;
  color: #ccc !important; /* Default color during transition */
  transition: opacity 0.5s ease, visibility 0.5s ease;
}

.artefact-icon--visible {
  opacity: 1;
  visibility: visible;
  color: #ccc !important; /* Maintain color during fade-in */
}

.download-message {
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.5s ease, visibility 0.5s ease;
}

.download-message--visible {
  opacity: 1;
  visibility: visible;
}

.download-message {
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.5s ease, visibility 0.5s ease;
}

.download-message--visible {
  opacity: 1;
  visibility: visible;
}

/* .youtube-1,
.youtube-2,
.youtube-3,
.youtube-4 {
  position: relative !important; 
  z-index: 1000 !important;        
} */

.youtube-3 {
  pointer-events: auto !important;
}




.youtube-4 {
  /* position: relative !important;  */
  /* z-index: 1000 !important;         */
}

.artefact-name-input-box{
  z-index: 100000000 !important;
}

/* Styles for smaller screens (e.g., phones) */
@media (max-width: 768px) {
  .pdf-embed-container {
    width: 90%; /* Adjust the width to fit smaller screens */
    height: auto; /* Auto height to maintain aspect ratio */
    max-width: 300px; /* Prevent the container from becoming too wide */
  }

  .pdf-embed {
    width: 100%; /* Make the embed take up full width */
    height: auto; /* Maintain aspect ratio */
    min-height: 200px; /* Ensure minimum height is maintained */
  }
  /* .artefact-zoom-transition {
    transition: transform 0.5s ease 0.5s, z-index 0.3s ease !important;
  } */

  .artefact-zoom-transition {
    /* Default mobile transition */
    transition: transform 0.5s, z-index 0.3s !important;
  }

  /* Conditional zoomed-in transition for mobile */
  .zoomed-in-mobile.artefact-zoom-transition {
    transition: transform 0.5s ease 0.5s, z-index 0.3s ease !important;
  }
}


/* For medium screens, including iPads */
@media (min-width: 769px) and (max-width: 1124px) {
  .pdf-embed-container {
    width: 90%; /* Adjust the width to fit smaller screens */
    height: auto; /* Auto height to maintain aspect ratio */
    max-width: 300px; /* Prevent the container from becoming too wide */
  }

  .pdf-embed {
    width: 100%; /* Make the embed take up full width */
    height: auto; /* Maintain aspect ratio */
    min-height: 200px; /* Ensure minimum height is maintained */
  }
  /* .artefact-zoom-transition {
    transition: transform 0.5s ease 0.5s, z-index 0.3s ease !important;
  } */

  .artefact-zoom-transition {
    /* Default mobile transition */
    transition: transform 0.5s, z-index 0.3s !important;
  }

  /* Conditional zoomed-in transition for mobile */
  .zoomed-in-mobile.artefact-zoom-transition {
    transition: transform 0.5s ease 0.5s, z-index 0.3s ease !important;
  }

}