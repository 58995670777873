@import url('https://fonts.googleapis.com/css2?family=Raleway:ital,wght@0,200;0,300;0,400;1,200;1,300;1,400&display=swap');

body {
    font-family: 'Raleway', sans-serif;
    background-color: #f8f9fa;
}

/* p {
    margin: 19px 0;
  }


h4{ 
    margin-bottom: -8px;
} */

/* Progress Bar Container */
.progress-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

/* Circular Progress Wrapper */
.progress-circle {
  position: relative;
  width: 100px;
  height: 100px;
  border-radius: 50%;
  background: conic-gradient(
    #7695ff var(--progress, 0deg), 
    #ccc var(--progress, 0deg)
  );
  display: flex;
  justify-content: center;
  align-items: center;
}

/* Progress Value Text */
.progress-percentage {
  position: absolute;
  font-size: 1.2em;
  color: #333;
}


body, html {
    overflow-x: hidden;
  }
  

  .no-select {
    -webkit-touch-callout: none; /* Disable callout, e.g., phone numbers in Safari */
    -webkit-user-select: none;   /* Disable text selection in Safari */
    -khtml-user-select: none;    /* Disable text selection in Konqueror */
    -moz-user-select: none;      /* Disable text selection in Firefox */
    -ms-user-select: none;       /* Disable text selection in Internet Explorer/Edge */
    user-select: none;           /* Disable text selection in non-prefixed browsers */
  }
  
  .body-container h4, 
  .body-container p {
      -webkit-touch-callout: none;
      -webkit-user-select: none;
      -khtml-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;
  }
  
/* TutorialModal.css */

/* Default layout for larger screens */
.body-container {
    height: 75%;
    width: 97%;
    background-color: #d4d4d4;
    display: flex;
    flex-direction: row;
    margin-bottom: 120px;
    border-radius: 8px;
    justify-content: center;
    align-items: center;
    position: relative;
  }
  
  /* Layout for smaller screens (e.g., phones) */
  @media (max-width: 768px) {
    .body-container {
      flex-direction: column;
    }
  
    .left-column {
      margin-left: 0;
      margin-top: 20px;
      text-align: center;
    }
  
    .right-column {
      margin-right: 0;
      width: 100%;
    }
    .arrow-styling{
      gap: 30px !important;
    }
  }
  

  