@import url('https://fonts.googleapis.com/css2?family=Raleway:ital,wght@0,200;0,300;0,400;1,200;1,300;1,400&display=swap');

/* .home-container {
    background-image: url('Gradient2.png');
    width: 100vw;
    height: 100vh;
    position: absolute;
    background-size: cover;
} */

.home-container {
    width: 100vw;
    height: 100vh;
    background-size: cover;
    background-position: center;
    /* Remove position: absolute unless you truly need it */
    
    display: flex;               /* make it a flex container */
    align-items: center;         /* vertically center */
    justify-content: center;     /* horizontally center */
    margin: 0;                   /* remove default browser margins if necessary */
    padding: 0;                  /* remove default browser paddings if necessary */
  }
  

.signIn-container .form-floating > .form-label {
    font-size: 18px !important; /* Adjust as needed */
    line-height: 1.3; /* Ensure the spacing looks good */
    font-family: 'Raleway', sans-serif; /* Ensure it uses your desired font */
    /* margin-top: -3px; */
}

.no-select {
    -webkit-touch-callout: none; /* Disable callout, e.g., phone numbers in Safari */
    -webkit-user-select: none;   /* Disable text selection in Safari */
    -khtml-user-select: none;    /* Disable text selection in Konqueror */
    -moz-user-select: none;      /* Disable text selection in Firefox */
    -ms-user-select: none;       /* Disable text selection in Internet Explorer/Edge */
    user-select: none;           /* Disable text selection in non-prefixed browsers */
  }
  

.signIn-container {
    font-family: raleway;
    width: 37vw;
    max-width: 550px; 
    min-width: 380px; /* Minimum width of the container */
    border: 3px;
    border-radius: 19px;
    font-family: 'Raleway', sans-serif;
    padding: 2%;
    background: rgba(200,200,200, 0.50);
    margin-top: 11%;
    margin-left: 10%;
}

.login-button-container {
    display:flex;
    justify-content:center;
    align-items: center;
}

.login-button {
    width: 130px;
}

.home-content {
    display: flex;
    align-items: center;
    gap: 20px; /* Space between the sign-in box and the logo */
}

.logo-container {
    display: flex;
    align-items: center;
    gap: 10px; /* Space between the logo icon and logo showcase */
}

.logo-icon {
    height: 80px; /* Adjust height as needed */
    width: auto;
}

.logo-showcase {
    height: 120px; /* Adjust height as needed */
    width: auto;
    margin-left: 5.5px;
}

.form-floating > label::after {
    background-color: transparent !important;
}

/* .low-connection-signin {
    top: 10px !important;
    left: 50% !important;
    margin-left: -56px !important;
  } */

@media (max-width: 768px) {
    /* @media (max-width: 1200px) { */
  .home-content {
      flex-direction: column; /* Stack children vertically */
      align-items: center; /* Center-align the items */
  }

  .low-connection-signin {
    /* position: 0 0 0 0;
    top: 10px !important;
    left: 50%;
    margin-left: -56px !important; */
  }
  .low-connection-signin.app-mobile {
    /* position: 0 0 0 0;
    top: 10px !important;
    left: 50%;
    margin-left: -56px !important; */
  }

  .signIn-container {
      position: relative; /* Ensure the adjustments work relative to its original position */
      width: 80%; /* Adjust width for mobile */
      transform: translate(-70px, -185px); /* Move the container left by 60px and up by 100px */
      text-align: center; /* Center-align text inside the container */
      height: 360px; /* Increase the height of the container */
      padding-top: 20px; /* Add some padding to the top */
      left: 13%;
      margin-top: 40%;
      margin-bottom: 0%;
      
  }

  .logo-container {
      order: -1; /* Place logo above the sign-in container */
      transform:  translate(-18px, -63px); /* Move the logo up by 60px */
      margin-bottom: 50px; /* Add some space below the logo */
  }

  .logo-icon {
      height: 50px; /* Smaller logo icon for mobile */
  }

  .logo-showcase {
      height: 80px; /* Smaller logo showcase for mobile */
      margin-left: 0; /* Center the logo on mobile */
  }
  .back-icon-signin {
    margin-left: 10px !important;
    margin-top: 3px !important;
  }
}

@media (min-width: 768px) and (max-width: 1170px) {
    .logo-container {
        gap: 0px; /* Adjust gap if necessary */
        margin-left: calc(-10px + ((100vw - 768px) * 20 / 402)) !important; /* Start at -20px and scale to 0px */
    }
    .low-connection-signin {
        top: 12% !important;
      }
    .signIn-container {
        margin-left: calc(20px + ((100vw - 768px) * 20 / 402)) !important;
    }
  
    .logo-icon {
        height: calc(53.3px + ((100vw - 768px) * 17.8 / 402)); /* Reduced scaling factor */
        width: auto;
    }
  
    .logo-showcase {
        height: calc(80px + ((100vw - 768px) * 26.7 / 402)); /* Reduced scaling factor */
        width: auto;
    }
}

