/* html, body {
    overscroll-behavior-x: none;
    touch-action: unset;
  } */


  .no-select {
    -webkit-touch-callout: none; /* Disable callout, e.g., phone numbers in Safari */
    -webkit-user-select: none;   /* Disable text selection in Safari */
    -khtml-user-select: none;    /* Disable text selection in Konqueror */
    -moz-user-select: none;      /* Disable text selection in Firefox */
    -ms-user-select: none;       /* Disable text selection in Internet Explorer/Edge */
    user-select: none;           /* Disable text selection in non-prefixed browsers */
  }
  


  .cancel-placement-btn {
    position: fixed;
    top: 0px; 
    right: -95px; /* Adjust as needed */
    padding: 10px 20px;
    background-color: #ff4d4d;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
    z-index: 1000; /* Ensure it stays on top */
    opacity: 0; /* Start hidden */
    /* pointer-events: auto;
    transition: opacity 0.4s ease-out;  */
    pointer-events: none; /* Prevent interaction when hidden */
    transition: opacity 0.4s ease-out, pointer-events 0.4s ease-out;
  }

  .cancel-all-placements-btn{
    position: fixed;
    top: 6px; 
    right: 110px !important; /* Adjust as needed */
    padding: 10px 20px;
    background-color: #ff4d4d;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
    z-index: 1000; /* Ensure it stays on top */
    opacity: 0; /* Start hidden */
    /* pointer-events: auto;
    transition: opacity 0.4s ease-out;  */
    pointer-events: none; /* Prevent interaction when hidden */
    transition: opacity 0.4s ease-out, pointer-events 0.4s ease-out;
  }
  
  .cancel-placement-btn, .cancel-all-placements-btn {
    transition: opacity 0.4s ease-out; /* Ensure this is consistent */
  }
  
  .cancel-placement-btn.show, .cancel-all-placements-btn.show {
    opacity: 1; /* Show button */
    pointer-events: auto; /* Enable interaction */
}

.cancel-placement-btn:not(.show), .cancel-all-placements-btn:not(.show) {
    opacity: 0; /* Hide button */
    pointer-events: none; /* Disable interaction */
}

.invalid-collection-message {
  position: fixed;
  top: 80px; /* Adjust as needed */
  left: 50%;
  transform: translateX(-50%);
  padding: 10px 20px;
  border-radius: 5px;
  color: blue;
  z-index: 1111150;
  opacity: 0; /* Hidden initially */
  pointer-events: none; /* Prevent interaction */
  transition: opacity 0.5s ease, visibility 0.5s ease;
  visibility: hidden; /* Hidden initially */
}

.invalid-collection-message.show {
  opacity: 1; /* Show when the class is applied */
  pointer-events: auto; /* Allow interaction if needed */
  visibility: visible;
}


/* .cancel-placement-btn,
.cancel-all-placements-btn {
  transition: opacity 0.5s ease, transform 0.5s ease;
  opacity: 0;
  transform: translateY(-20px);
}

.cancel-placement-btn.show,
.cancel-all-placements-btn.show {
  opacity: 1;
  transform: translateY(0);
}

.cancel-all-placements-btn {
  background: #FF666D;
} */
  .placing-artefact-number{
  z-index: 1000 !important;
}

  .fade-no-zoom-message {
    transition: opacity 0.5s ease-in-out, visibility 0.5s;
    opacity: 0;
    visibility: hidden; /* Initially hidden */
    position: absolute;
    top: 160px; /* Positioned 10px from the top of the PannableBox */
    left: 50%;
    transform: translate(-50%, -50%);
    color: red;
    padding: 10px 20px;
    border-radius: 10px;
    z-index: 1000;
    pointer-events: none; /* Prevent interaction */
    display: flex;
    justify-content: center;
    align-items: center;
}


.fade-no-zoom-message.show {
    opacity: 1;
    visibility: visible; /* Show the element when the 'show' class is applied */
}

.fade-no-zoom-message.app-mobile {
  top: 204px; /* Positioned 10px from the top of the PannableBox */
}

  

  @media (max-width: 1210px) {
    .zoom-slider-container.bottom-center {
      /* bottom: 140px !important; */
      left: 50% !important;
      /* top: 93% !important; */
      bottom:20px !important;
      /* transform: translateX(-50%) !important; */
      transform: translate(-50%, 95%) !important;
      z-index: 1000000 !important;

    }
    .zoom-slider-container.bottom-center.move-up {
      bottom: calc(80px + 2vh) !important
      /* transform: translate(-50%, 83%) !important; */
    }
    .zoom-slider-container.bottom-center.home-page-move-up {
      /* bottom: calc(80px  + 70px) !important; */
      bottom: calc(80px + 82px) !important

    }
    .zoom-slider-container.bottom-center.app-mobile-landscape {
      bottom:35px !important;
    }


    .cancel-all-placements-btn{
      right: 90px !important
    }
  }